<template>
	<video-base :id="String(id)" category="サイバー最新トレンド" routerName="Trend" />
</template>

<script>
import VideoBase from "@/components/VideoBase";
export default {
	components: {
		VideoBase,
	},
  props: ["id"],
}
</script>